<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
     <!-- BOC:[error] -->
     <AError
        v-if="api.isError"
        :api="api"
        :callbackReset="() => (api.isError = false)"
      ></AError>
    <!-- EOC -->
    <v-card
      class="mx-auto pa-3 mb-3"
      max-width="800"
      outlined
      min-height="200"
    >
      <v-card-title>Setup Account</v-card-title>
      <v-stepper elevation="0" value="3">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="true">Verify Mobile</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="true">Set Password</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="false">Subscribe Hamochi Bot</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4" :complete="false">Done</v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-card-subtitle><b>Subscribe Hamochi Bot</b></v-card-subtitle>
      <v-card-text>
        <v-img
          src="/images/HamochiBot.png"
          max-height="200"
          contain
        ></v-img>
        <div>Our system sends notifications to our users via Telegram.</div>
        <ol>
          <li>Using mobile phone, click the bottom <b>"GO TO HAMOCHI BOT"</b> button.</li>
          <li>Follow its instruction and subscribe to it.</li>
          <li>After you have subscribed, click <b>"I HAVE SUBSCRIBED!"</b> button.</li>
          <li>If you saw an error message "Not yet subscribe to Hamochi Bot", go back to Telegram Hamochi Bot, unsubscribe then subscribe again. Enter the mobile number carefully.</li>
        </ol>
      </v-card-text>
      <v-card-text>
        <div class="text-center pt-3">
          <v-btn
            width="75%"
            rounded
            color="success"
            dark
            href="https://t.me/HamochiBot"
            target="_blank"
          >
            Go to Hamochi Bot
          </v-btn>
        </div>
        <div class="text-center pt-3">
          <v-btn
            v-if="!isSubscribed"
            width="75%"
            rounded
            color="primary"
            dark
            @click="submit()"
          >
            I have subscribed!
          </v-btn>
          <v-btn
            v-else
            width="75%"
            rounded
            disabled
          >
            Thank you
          </v-btn>
        </div>
        <div class="text-center pt-3">
          <v-btn
            width="75%"
            rounded
            color="primary"
            outlined
            @click="skip()"
          >
            Skip Now, subscribe later.
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
    
<script>
import { mapState } from "vuex";
import delay from 'delay'
export default {
  components: {
    //
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
     //BOC:[api]
     api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    isSubscribed: false,
  }),
  created() {
    //BOC:[api]
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/account/auth/telegram/subscribe";
    
    this.api.params = {
      mobile:this.auth.Account.mobile
    }

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = async (resp) => {
      this.api.isLoading = false;
      this.isSubscribed = resp
      //
      await delay(1000);
      //
      var authData = this.$_.cloneDeep(this.auth);
      authData.Account.hasTelegramUid = true;
      this.$store.commit("updateAuth", authData);
      //
      this.$router.push({ name: "PageEducatorDashboard" });
    };
    //EOC
  },
  mounted() {
    //
  },
  watch: {
    //
  },
  methods: {
    submit() {
      this.$api.fetch(this.api)
    },
    skip(){
      var authData = this.$_.cloneDeep(this.auth);
      authData.Account.hasTelegramUid = true;
      this.$store.commit("updateAuth", authData);
      this.$router.push({ name: "PageEducatorDashboard" });
    },
  },
};
</script>
<style scoped>
</style>